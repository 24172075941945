
















import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { ReferralViewModel } from '../viemmodel/referral-viewmodel'

@Observer
@Component({
  components: {
    'referral-milestones': () => import('../components/referral-milestones.vue'),
  },
})
export default class extends Vue {
  @Inject() vm!: ReferralViewModel
}
